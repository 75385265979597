<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';
	import swal from 'sweetalert';

	export default {
		name: "WebSettings",
		extends: Base,
		data() {
			return {
				Name: "WebSettings",
				mrLevel: {},
				sosial_media_cape: {},
				sosial_media_belli: {},
				sosial_media_detail: {},
				sc_footer: {},
				cmp_name: {},
				cmp_address: {},
				cmp_number: {},
				cmp_email: {},
				open_service: {},
				cape_username_instagram: {},
				cape_client_secret_instagram: {},
				belli_username_instagram: {},
				belli_client_secret_instagram: {},
			}
		},
		computed:{
			urlCodeBelliInstagram(){
				var $client_id = this.belli_username_instagram.ms_value
				return "https://www.instagram.com/oauth/authorize?client_id="+$client_id+"&redirect_uri=https://bellissimowines.com/backoffice/web-setting&scope=user_profile,user_media&response_type=code"
			},
			urlCodeCapeInstagram(){
				var $client_id = this.cape_username_instagram.ms_value
				return "https://www.instagram.com/oauth/authorize?client_id="+$client_id+"&redirect_uri=https://capediscovery-wines.com/backoffice/web-setting&scope=user_profile,user_media&response_type=code"
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
			this.generateTokenInstagram();
		},
		methods: {
			showMediaSosial(id) {
				$("#editSosmed").modal()
				this.sosial_media_detail = {}
				BOGen.apirest("/" + this.Name, {
					id: id,
					type: 'showMediaSosial'
				}, (err, resp) => {
					if (err) console.log(err)
					this.sosial_media_detail = resp.data
				}, "POST")
			},
			generateInstagram() {
				this.loadingOverlay = true
				let username_cape = this.cape_username_instagram.ms_value
				let username_belli = this.belli_username_instagram.ms_value
				BOGen.apirest("/" + this.Name, {
					username_cape: username_cape,
					username_belli: username_belli,
					type: 'generateInstagram'
				}, (err, resp) => {
					this.loadingOverlay = false
					if (err) return swal(err.resp.message, "", "warning")
					return swal(resp.message, "", "success")
				}, "POST")
			},
			submitForm(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input.sc_footer = this.sc_footer
				input.cmp_name = this.cmp_name
				input.cmp_address = this.cmp_address
				input.cmp_number = this.cmp_number
				input.cmp_email = this.cmp_email
				input.open_service = this.open_service
				input.cape_username_instagram = this.cape_username_instagram
				input.cape_client_secret_instagram = this.cape_client_secret_instagram
				input.belli_username_instagram = this.belli_username_instagram
				input.belli_client_secret_instagram = this.belli_client_secret_instagram
				input.type = 'update'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					if (resp.error == 1) return Gen.info(resp.message, resp.style, 3000, '.info')
					if (resp.success) {
						Gen.info(resp.message, resp.style, 3000, '.info')
						this.refreshData()
					}
				}, "POST")
			},
			editSosialMedai(e, callback) {
				if (e && e.btnLoading()) return;
				let input = {}
				input = this.sosial_media_detail
				input.type = 'editSosialMedai'
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if (e) e.btnUnloading()
					$("#editSosmed").modal("hide")
					if (resp.success) swal(resp.message, '', 'success')
					this.refreshData()
				}, "POST")
			},
			generateTokenInstagram() {
				if(!this.$route.query.code) return console.log("no code")
				console.log("generate running...")
				var input = {}
				input.code = this.$route.query.code
				input.type = "generateTokenInstagram"
				input.url = this.baseUrl
				BOGen.apirest("/" + this.Name, input, (err, resp) => {
					if(err) return swal(err.resp.message, '', 'warning')
					swal(resp.message, '', 'success')
				}, "POST")
			},
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">Social Media Cape Discovery</h5>
						</div>
						<hr>
						<form action="">
							<div class="row">
								<div class="col-md-3" v-for="(v,k) in sosial_media_cape" :key="k">
									<div class="card border">
										<div class="card-body">
											<div class="row">
												<div class="col-md-9">
													<h5 class="card-title">{{v.mas_title}}</h5>
													<span class="badge badge-success"
														v-if="v.mas_status == 'Y'">Active</span>
													<span class="badge badge-danger" v-else>Inactive</span>
												</div>
												<div class="col-md-3">
													<p class="mb-0 text-right">
														<a href="javascript:;" v-if="page.moduleRole('Update')"
															@click="showMediaSosial(v.mas_id)"
															class="text-inverse icon_action"><i
																class="ti-pencil"></i></a>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">Social Media Bellissimo</h5>
						</div>
						<hr>
						<form action="">
							<div class="row">
								<div class="col-md-3" v-for="(v,k) in sosial_media_belli" :key="k">
									<div class="card border">
										<div class="card-body">
											<div class="row">
												<div class="col-md-9">
													<h5 class="card-title">{{v.mas_title}}</h5>
													<span class="badge badge-success"
														v-if="v.mas_status == 'Y'">Active</span>
													<span class="badge badge-danger" v-else>Inactive</span>
												</div>
												<div class="col-md-3">
													<p class="mb-0 text-right">
														<a href="javascript:;" v-if="page.moduleRole('Update')"
															@click="showMediaSosial(v.mas_id)"
															class="text-inverse icon_action"><i
																class="ti-pencil"></i></a>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="d-flex justify-content-between align-items-center">
							<h5 class="card-title">Generate Feed Insatgram</h5>
						</div>
						<hr>
							<div class="row">
								<div class="col-md-6" v-if="baseUrl.indexOf('bellissimo')>-1">
										<a :href="urlCodeBelliInstagram" target="_blank" class="btn btn-success btn-block">Generate Feed Instagram Bellissimo</a>
								</div>
								<div class="col-md-6" v-else>
										<a :href="urlCodeCapeInstagram" target="_blank" class="btn btn-success btn-block">Generate Feed Instagram Cape Discovery</a>
								</div>
							</div>
					</div>
				</div>
			</div>
			<div class="col-12">
				<VForm @resp="submitForm" action="post">
					<div class="card">
						<div class="card-body">
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="card-title">Footer</h5>
							</div>
							<hr>
							<div class="row">
								<div class="col-md-12 info"></div>
								<div class="col-md-6">
									<label for="">Footer Logo</label>
									<div class="row">
										<div class="col-12">
											<Uploader isBG class="prev-red" name="bu_pic" type="img_footer"
												uploadType="cropping" :param="{thumbnail:true}"
												v-model="sc_footer.sc_image1"></Uploader>
										</div>
										<div class="col-12 mt-4">
											<div class="form-group">
												<BoField name="sc_desc_en">
													<CKEditor name="content4" type="simple"
														v-model="sc_footer.sc_desc_en"></CKEditor>
												</BoField>
											</div>
										</div>
										<div class="col-12">
											<div class="form-group">
												<BoField name="sc_desc_id">
													<CKEditor name="content5" type="simple"
														v-model="sc_footer.sc_desc_id"></CKEditor>
												</BoField>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<BoField :label="'Company Name'" v-model="cmp_name.ms_value"></BoField>
										<BoField :label="'Company Email'" v-model="cmp_email.ms_value"></BoField>
										<BoField :label="'Client ID Instagram Cape Discovery'" v-model="cape_username_instagram.ms_value"></BoField>
										<BoField :label="'Client Secret Instagram Cape Discovery'" v-model="cape_client_secret_instagram.ms_value"></BoField>
										<BoField :label="'Client ID Instagram Bellissimo'" v-model="belli_username_instagram.ms_value"></BoField>
										<BoField :label="'Client Secret Instagram Bellissimo'" v-model="belli_client_secret_instagram.ms_value"></BoField>
									</div>
									<div class="form-group">
										<BoField name="sc_desc_en" :label="'Company Detail'">
											<CKEditor name="content1" type="simple" v-model="cmp_address.ms_value">
											</CKEditor>
										</BoField>
									</div>
									<div class="form-group">
										<BoField name="sc_desc_en" :label="'Open Service (EN)'">
											<CKEditor name="content2" type="simple" v-model="open_service.sc_desc_en">
											</CKEditor>
										</BoField>
									</div>
									<div class="form-group">
										<BoField name="sc_desc_id" :label="'Open Service (ID)'">
											<CKEditor name="content3" type="simple" v-model="open_service.sc_desc_id">
											</CKEditor>
										</BoField>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="card">
							<div class="card-body text-right">
								<button type="submit" v-if="page.moduleRole('Update')"
									class="btn btn-loading btn-success">Submit
									Changes</button>
							</div>
						</div>
					</div>
				</VForm>
			</div>
		</div>
		<div id="editSosmed" class="modal" tabindex="-1" role="dialog" aria-labelledby="add_team" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="prod_cat">Edit Social Media</h4>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
					</div>
					<VForm @resp="editSosialMedai" action="post">
						<div class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="form-group">
										<BoField v-model="sosial_media_detail.mas_val"
											:label="sosial_media_detail.mas_title+' Link'"></BoField>
									</div>
								</div>
								<div class="col-12">
									<Checkbox class="mt-4" v-model="sosial_media_detail.mas_status">Active</Checkbox>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="submit" class="btn btn-rounded btn-loading btn-info">Submit</button>
						</div>
					</VForm>
				</div>
			</div>
		</div>
	</div>
</template>